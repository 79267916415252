/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode } from "react"

import Header from "./header"
import Footer from "./footer"
import "./typography.css"
import "./layout.css"

export interface Props {
  children: ReactNode
}

const Layout = ({ children }: Props) => (
  <>
    <Header />
    <div>
      <main>{children}</main>
      <Footer />
    </div>
  </>
)

export default Layout
