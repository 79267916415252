import React from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"

const styles = createStyles({
  navIcon: {
    transform: "scale(0.8) rotate(0deg)",
    width: 40,
    height: 30,
    position: "relative",
    margin: "0",
    transition: ".5s ease-in-out",
    cursor: "pointer",

    "& span": {
      display: "block",
      position: "absolute",
      height: 6,
      width: "100%",
      background: "#000000",
      borderRadius: 6,
      opacity: "1",
      left: "0",
      transform: "rotate(0deg)",
      transition: ".25s ease-in-out",
    },

    "& span:nth-child(1)": {
      top: 0,
    },
    "& span:nth-child(2),& span:nth-child(3)": {
      top: 12,
    },

    "& span:nth-child(4)": {
      top: 24,
    },
  },
  open: {
    "& span:nth-child(1)": {
      top: 18,
      width: "0%",
      left: "50%",
    },
    "& span:nth-child(2)": {
      transform: "rotate(45deg)",
    },
    "& span:nth-child(3)": {
      transform: "rotate(-45deg)",
    },
    "&  span:nth-child(4)": {
      top: 18,
      width: "0%",
      left: "50%",
    },
  },
})

export interface Props {
  open: boolean
  onClick: () => void
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const BurguerIcon = ({ classes, open, onClick }: PropsAndStyles) => {
  return (
    <div
      className={classNames(classes.navIcon, open ? classes.open : "")}
      onClick={onClick}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default withStyles(styles)(BurguerIcon)
