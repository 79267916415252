import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"

import FacebookIcon from "../images/icons/facebook.svg"
import InstagramIcon from "../images/icons/instagram.svg"
import LinkedInIcon from "../images/icons/linkedIn.svg"
import TikTokIcon from "../images/icons/tik-tok.svg"
import TwitterIcon from "../images/icons/twitter.svg"
import YoutubeIcon from "../images/icons/youtube.svg"

const styles = createStyles({
  socials: {
    width: "100%",
    display: "flex",
    justifyContent: "inherit",
    "& a": {
      margin: "0 5%",
    },
    "& :first-child": {
      marginLeft: 0,
    },
    "& :last-child": {
      marginRight: 0,
    },
  },
  social: {
    fill: "inherit",
  },
})

export interface Props {
  color: string
  size: number
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const Socials = ({ classes, color, size }: PropsAndStyles) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socials {
              facebook
              instagram
              linkedIn
              tikTok
              twitter
              youtube
            }
          }
        }
      }
    `
  )

  const {
    facebook,
    instagram,
    linkedIn,
    tikTok,
    twitter,
    youtube,
  } = site.siteMetadata.socials

  return (
    <div className={classes.socials} style={{ fill: color }}>
      {facebook && (
        <a
          href={facebook}
          target="_blank"
          className={classes.social}
          style={{ width: size, height: size }}
        >
          <FacebookIcon />
        </a>
      )}
      {instagram && (
        <a
          href={instagram}
          target="_blank"
          className={classes.social}
          style={{ width: size, height: size }}
        >
          <InstagramIcon />
        </a>
      )}
      {linkedIn && (
        <a
          href={linkedIn}
          target="_blank"
          className={classes.social}
          style={{ width: size, height: size }}
        >
          <LinkedInIcon />
        </a>
      )}
      {tikTok && (
        <a
          href={tikTok}
          target="_blank"
          className={classes.social}
          style={{ width: size, height: size }}
        >
          <TikTokIcon />
        </a>
      )}
      {twitter && (
        <a
          href={twitter}
          target="_blank"
          className={classes.social}
          style={{ width: size, height: size }}
        >
          <TwitterIcon />
        </a>
      )}
      {youtube && (
        <a
          href={youtube}
          target="_blank"
          className={classes.social}
          style={{ width: size, height: size }}
        >
          <YoutubeIcon />
        </a>
      )}
    </div>
  )
}

export default withStyles(styles)(Socials)
